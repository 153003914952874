<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}通知`"
    width="965px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="notification-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="title">
        <template #label>
          <span class="label">標題</span>
          <span class="tips">顯示於POS管理頁(上限24字)</span>
        </template>
        <el-input v-model="ruleForm.title" placeholder="請輸入標題" maxlength="24" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="content" style="grid-area: 1/2/4/3">
        <template #label>
          <span class="label">通知內容</span>
          <span class="tips">顯示於POS管理頁(上限160字)</span>
        </template>
        <el-input v-model="ruleForm.content" type="textarea" :rows="11" placeholder="請輸入訊息內容" resize="none" />
      </el-form-item>
      <!-- 門市 -->
      <el-form-item class="form-item" prop="available_shop_type">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.available_shop_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.available_shop_type === 2"
          v-model="ruleForm.available_shop_value_array"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.available_shop_type === 3"
          v-model:file_url="ruleForm.available_shop_file_url"
          v-model:file_name="ruleForm.available_shop_file_name"
        ></upload-file>
      </el-form-item>
      <!-- 日期 -->
      <el-form-item class="form-item" prop="send_datetime">
        <template #label>
          <span class="label">通知日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.send_datetime"
          type="datetime"
          placeholder="請選擇日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :disabled-date="disabledDate"
          style="width: 100%; margin-top: 5px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, defineAsyncComponent, watch, reactive, getCurrentInstance } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useActions } from '@/utils/mapStore';
import { time_period_type_options, available_shop_type_options, area_options } from '@/utils/define';
export default {
  name: 'notification-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //--------------------------------------------------------------------
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        ruleForm.value.send_datetime = formatDateTime('YYYY/MM/DD HH:mm', cusProps.init.send_datetime);
      } else
        ruleForm.value = {
          id: null,
          title: '',
          content: '',
          send_datetime: null,
          available_shop_type: 1,
          available_shop_value_array: [],
          available_shop_file_url: null,
          available_shop_file_name: null,
        };
    }
    //日期相關-----------------------------------------------------
    //禁用日期
    const disabledDate = (time) => {
      const today = new Date();
      if (time.getTime() < today.getTime() - 24 * 60 * 60 * 1000) return true;
    };
    //--------------------------------------------------------------------
    //radio切換-賦予初始值、清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    //切換門市
    watch(
      () => [
        ruleForm.value.available_shop_type,
        ruleForm.value.available_shop_value_array,
        ruleForm.value.available_shop_file_url,
      ],
      () => {
        clearItemValidate(ruleFormRef.value, 'available_shop_type');
      }
    );
    //--------------------------------------------------------------------
    //驗證
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.available_shop_value_array?.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.available_shop_file_name || !ruleForm.value.available_shop_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const rules = reactive({
      title: [{ required: true, message: '請輸入標題', trigger: 'blur' }],
      content: [
        { required: true, message: '請輸入訊息內容', trigger: 'blur' },
        { max: 160, message: '字數超過160字', trigger: 'blur' },
      ],
      send_datetime: [{ required: true, message: '請選擇日期', trigger: 'blur' }],
      available_shop_type: [{ validator: validateShop, trigger: 'blur' }],
    });
    //--------------------------------------------------------------------
    //送出
    const doSaveNotification = useActions('notification', ['doSaveNotification']);
    const handleSubmit = async (form) => {
      try {
        console.log(ruleForm.value);
        if (!form) return;
        await form.validate();
        ruleForm.value.send_datetime = formatDateTime('YYYYMMDDHHmmss', ruleForm.value.send_datetime);
        await doSaveNotification(ruleForm.value);
        if (cusProps.action === 'create' || cusProps.action === 'edit') {
          proxy.$message({
            type: 'success',
            message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
          });
        }
        submit(cusProps.action);
        close();
      } catch (e) {
        e;
      }
    };
    return {
      time_period_type_options,
      available_shop_type_options,
      area_options,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      disabledDate,
      clearItemValidate,
      rules,
      handleSubmit,
    };
  },
};
</script>
<style lang="scss" scoped>
.notification-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
:deep(.el-input__suffix-inner > :first-child) {
  margin-left: 0;
}
</style>
